import {
  FAILED_BILL_FETCH,
  FETCH_BILLS,
  SET_BILLS,
} from "../actions/billActions";

const initialState = {
  fetching: false,
  bills: {},
  error: null,
};

export const billReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BILLS:
      return { fetching: true, bills: {}, error: null };
    case SET_BILLS:
      return { ...state, fetching: false, bills: payload, error: null };
    case FAILED_BILL_FETCH:
      return { ...state, fetching: false, bills: {}, error: payload };

    default:
      return state;
  }
};
