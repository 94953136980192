import axios from "axios";
import { apiErrorMessage, reduxErrorMessage } from "src/utils/helper";
import { fetchAllBills } from "src/utils/NetworkUtils";
export const FETCH_BILLS = "FETCH_BILLS";
export const SET_BILLS = "SET_BILLS";
export const FAILED_BILL_FETCH = "FAILED_BILL_FETCH";

export const fetchBills = () => ({
  type: FETCH_BILLS,
});

export const setBills = (payload) => ({
  type: SET_BILLS,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_BILL_FETCH,
  payload,
});

export const getAllBills =
  ({ filterQueryArr = [], log = false } = {}) =>
  async (dispatch) => {
    dispatch(fetchBills());
    try {
      const res = await fetchAllBills(filterQueryArr, log);
      dispatch(setBills(res));
    } catch (error) {
      const message = apiErrorMessage(error);
      dispatch(setError(message));
    }
  };
