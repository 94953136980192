import { combineReducers } from "@reduxjs/toolkit";

import { apiModeReducer } from "./reducers/apiModeReducer";
import { clientUserReducer } from "./reducers/clientUserReducer";
import {
  checkoutReducer,
  newCheckoutSessionReducer,
} from "./reducers/checkoutReducer";
import { paymentReducer } from "./reducers/paymentReducer";
import { walletReducer } from "./reducers/walletReducer";
import { refundReducer, newRefundReducer } from "./reducers/refundReducer";
import { webhookReducer } from "./reducers/webhookReducer";
import { walletTansactionReducer } from "./reducers/walletTransactionReducer";
import { customerReducer } from "./reducers/customerReducer";
import { accountSummaryReducer } from "./reducers/accountSummaryReducer";
import { organizationAccountsReducer } from "./reducers/organizationAccountsReducer";
import {
  newVirtualAccountsReducer,
  virtualAccountsReducer,
} from "./reducers/virtualAccountReducer";
import { payoutReducer } from "./reducers/payoutReducer";
import { transactionReducer } from "./reducers/tansactionReducer";
import { billReducer } from "./reducers/billReducer";

const rootReducer = combineReducers({
  mode: apiModeReducer,
  clientUsers: clientUserReducer,
  checkoutSession: checkoutReducer,
  newCheckoutSession: newCheckoutSessionReducer,
  payment: paymentReducer,
  bill: billReducer,
  wallet: walletReducer,
  customer: customerReducer,
  refund: refundReducer,
  newRefund: newRefundReducer,
  webhook: webhookReducer,
  walletTransaction: walletTansactionReducer,
  transactions: transactionReducer,
  accountSummary: accountSummaryReducer,
  organizationAccounts: organizationAccountsReducer,
  virtualAccounts: virtualAccountsReducer,
  newVirtualAccount: newVirtualAccountsReducer,
  payout: payoutReducer,
});

export default rootReducer;
