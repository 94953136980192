import { useState, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { SidebarContext } from "src/contexts/SidebarContext";

import PropTypes from "prop-types";
import { Button, Badge, Collapse, ListItem, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import { useSelector } from "src/store";
import useAuth from "src/hooks/useAuth";

const SidebarMenuItem = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  name,
  isExpanded = false,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState(isExpanded ? true : openParent);
  const { t } = useTranslation();
  const { user, kyb_status, gca_prod_status } = useAuth();
  const { toggleSidebar } = useContext(SidebarContext);
  const { mode } = useSelector((state) => state.mode);
  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ "Mui-active": menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem key={name} {...rest}>
      <Button
        activeClassName={
          (
            mode === "test"
              ? false
              : !(
                  (kyb_status === "APPROVED" &&
                    user.is_mobile_verified &&
                    user.is_email_verified) ||
                  name === "Home"
                )
          )
            ? "None"
            : "Mui-active"
        }
        component={RouterLink}
        exact
        onClick={toggleSidebar}
        to={link}
        disabled={
          mode === "test"
            ? false
            : name === "Create international payout" &&
              gca_prod_status !== "COMPLETED"
            ? true
            : !(
                (kyb_status === "APPROVED" &&
                  user.is_mobile_verified &&
                  user.is_email_verified) ||
                name === "Home"
              )
        }
        startIcon={Icon && <Icon />}
      >
        {name === "AR Invoices" ? (
          <>
            AR Invoices
            <Box
              sx={{
                paddingX: "6px",
                marginLeft: "8px",
                height: "24px",
                border: "1px solid #fff",
                borderRadius: "4px",
                backgroundColor: "#0048E5",
                color: "#fff",
                fontSize: "8x",
                fontWeight: "medium",
              }}
            >
              New
            </Box>
          </>
        ) : (
          t(name)
        )}
        {badge && <Badge badgeContent={badge} />}
        {/* {target ? (
          <a target={target} href="https://docs.pay-now.io/">
            {t(name)}
            {badge && <Badge badgeContent={badge} />}
          </a>
        ) : (
          <>
            {t(name)}
            {badge && <Badge badgeContent={badge} />}
          </>
        )} */}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
};

export default SidebarMenuItem;
